<template>
    <div id="app">
        <div style="display: grid">
            <transition :name="slideDirection">
                <div style="grid-column: 1; grid-row: 2" :key="page.id">
                    <Page
                        v-if="!showEnd"
                        @answer="addAnswer"
                        @next="nextPage"
                        @previous="previousPage"
                        :survey="survey"
                        :page="page"
                        :answer="answer"
                        :currentPage="currentPage + 1"
                        :maxPages="umfrageData.survey.pages.length"
                    />
                    <EndPage
                        v-if="showEnd"
                        :survey="survey"
                        @send="sendSurvey"
                        @cancel="cancelSurvey"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<!-- eslint-disable vue/require-prop-type-constructor -->
<script>
// Code muss hier sein, weil die main.js nicht mit kompiliert wird
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";

import Page from "./components/Page";
import EndPage from "./components/EndPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { setApiUrl, debugData } from "./config";

// API-Url setzen
setApiUrl();

export default {
    name: "app",
    components: {
        Page,
        EndPage,
    },
    props: {
        devdata: "",
        jsonurl: "",
    },
    data: function () {
        return {
            answers: [],
            umfrageData:
                this.getScriptData() ??
                this.getUrlData() ??
                this.getDevData() ??
                debugData,
            currentPage: 0,
            slideDirection: "slide-right",
            showEnd: false,
        };
    },
    computed: {
        project: function () {
            return this.umfrageData;
        },
        survey: function () {
            return this.umfrageData.survey;
        },
        page: function () {
            if (this.umfrageData == null) return null;

            return this.umfrageData.survey.pages[this.currentPage];
        },
        answer: function () {
            return this.answers.find((x) => x.pageID == this.page.id);
        },
    },
    watch: {
        devdata: function (val) {
            if (val == null) {
                this.umfrageData = null;
                this.currentPage = 0;
            } else this.umfrageData = JSON.parse(val);
        },
    },
    methods: {
        nextPage: function () {
            this.slideDirection = "slide-left";

            var nextPage = this.currentPage + 1;
            if (nextPage < this.umfrageData.survey.pages.length) {
                this.currentPage = nextPage;
            } else {
                this.showEnd = true;
                this.currentPage = 0;
            }
        },
        previousPage: function () {
            this.slideDirection = "slide-right";

            var previousPage = this.currentPage - 1;
            if (previousPage >= 0) {
                this.currentPage = previousPage;
            }
        },
        getUrlData() {
            if (this.jsonurl == "") return null;
            //TODO: Implement JsonURL Loading
            return null;
        },
        getScriptData() {
            var data =
                document.getElementById("umfrageData")?.innerHTML ?? null;
            if (data == null) return null;

            return JSON.parse(data);
        },
        getDevData() {
            if (this.devdata == null) return null;

            return JSON.parse(this.devdata);
        },
        addAnswer(data) {
            if (data.elementType == 1 || data.elementType == 3) {
                if (this.answer == undefined) this.answers.push(data);
                else {
                    var answerIndex = this.answers.findIndex(
                        (x) => x.pageID == this.page.id
                    );

                    this.answers.splice(answerIndex, 1);
                    this.answers.push(data);
                }
            } else if (data.elementType == 2) {
                if (this.answer == undefined)
                    this.answers.push({
                        pageID: data.pageID,
                        elementType: data.elementType,
                        selection: [data.elementID],
                    });
                else {
                    var elementIndex = this.answer.selection.findIndex(
                        (x) => x == data.elementID
                    );

                    if (elementIndex == -1)
                        this.answer.selection.push(data.elementID);
                    else this.answer.selection.splice(elementIndex, 1);
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        async sendSurvey(personalData) {
            await axios.post("Result", {
                surveyId: this.survey.id,
                data: JSON.stringify({
                    answers: this.answers,
                    personalData,
                })
            });
        },
        cancelSurvey: function() {
            this.showEnd = false;
            this.currentPage = 0;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/slide.scss";

#app {
    overflow: hidden;

    background: $unnamed-color-ffffff 0% 0% no-repeat padding-box;
}
</style>