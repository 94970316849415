<template>
    <div class="page">
        <p class="current-page">Frage {{ currentPage }} von {{ maxPages }}</p>
        <h2 class="titel">{{ page.title }}</h2>
        <div class="data" v-html="page.data"></div>
        <p v-if="page.elementType == 3" class="extra-text">
            {{ page.elements[0] && page.elements[0].label ? page.elements[0].label : 'Freien Text eingeben' }}
        </p>
        <div
            class="item-container"
            v-if="page.elementType == 1 || page.elementType == 2"
        >
            <div class="selection">
                <button
                    v-for="element in page.elements"
                    :key="element.id"
                    class="item"
                    :class="{
                        selected:
                            answer != undefined &&
                            ((page.elementType == 1 &&
                                answer.elementID == element.id) ||
                                (page.elementType == 2 &&
                                    answer.selection.includes(element.id))),
                    }"
                    @click="
                        $emit('answer', {
                            pageID: page.id,
                            elementType: page.elementType,
                            elementID: element.id,
                        })
                    "
                >
                    <div
                        class="check-box"
                        :class="{
                            selected:
                                answer != undefined &&
                                page.elementType == 2 &&
                                answer.selection.includes(element.id),
                        }"
                        v-if="page.elementType == 2"
                    >
                        <font-awesome-icon
                            v-show="
                                answer != undefined &&
                                page.elementType == 2 &&
                                answer.selection.includes(element.id)
                            "
                            :icon="['fas', 'check']"
                        />
                    </div>
                    <div
                        class="selected-box"
                        :class="{
                            selected:
                                answer != undefined &&
                                page.elementType == 1 &&
                                answer.elementID == element.id,
                        }"
                        v-if="page.elementType == 1"
                    >
                        <div
                            v-show="
                                answer != undefined &&
                                page.elementType == 1 &&
                                answer.elementID == element.id
                            "
                            class="mark"
                        ></div>
                    </div>

                    <img v-if="element.icon != '' && element.icon != null" class="icon" :src="assetUrl + element.icon" />
                    <span>{{ element.data }}</span>
                </button>
            </div>
        </div>
        <div class="input-container" v-if="page.elementType == 3 && page.elements[0]">
            <input
                ref="elementText"
                :placeholder="page.elements[0].data"
                type="text"
            />
        </div>
        <hr class="spacer" />
        <div class="page-controls">
            <button v-if="currentPage != 1" class="back" @click="previousPage">
                Zurück
            </button>
            <button
                :class="{
                    next: currentPage != maxPages,
                    complete: currentPage == maxPages,
                }"
                @click="nextPage"
            >
                {{ currentPage == maxPages ? "Anfrage absenden" : "Weiter" }}
            </button>
        </div>
        <p
            v-if="
                survey.phonenumber != null &&
                survey.phonenumber != undefined &&
                survey.phonenumber != ''
            "
            class="help-info"
        >
            <font-awesome-icon
                class="icon"
                :icon="['fas', 'question-circle']"
            />Brauchen Sie Hilfe?<span
                >Rufen Sie uns an: {{ survey.phonenumber }}</span
            >
        </p>
    </div>
</template>

<script>
import { getAssetsUrl } from '@/config';

export default {
    name: "PageElement",
    props: {
        survey: Object,
        page: Object,
        answer: Object,
        currentPage: Number,
        maxPages: Number,
    },
    data() {
        return {
            assetUrl: getAssetsUrl()
        }
    },
    methods: {
        nextPage: function () {
            if (this.page.elementType == 3) {
                var value = this.$refs.elementText.value;

                this.$emit("answer", {
                    pageID: this.page.id,
                    elementType: this.page.elementType,
                    elementID: this.page.elements[0].id,
                    data: value,
                });
            }

            this.$emit("next");
        },
        previousPage: function () {
            this.$emit("previous");
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    .current-page {
        margin-bottom: 8px;

        font-size: 12px;
        color: #999999;
    }

    .titel {
        margin-bottom: 32px;

        color: #333333;
        word-break: break-word;
    }

    .data >>> * {
        padding: 0;
        margin: 0;

        word-break: break-word;
    }

    .extra-text {
        margin-bottom: 4px;

        font-size: 12px;
        color: #333333;
    }

    .item-container {
        margin: 0 auto;

        .selection {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 16px;

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }

            .item {
                flex: 1;

                border: 2px solid #CCCCCC;
                background: #FFFFFF 0% 0% no-repeat padding-box;

                @include bold-text-16;
                color: black;
                padding: 24px 16px 24px 48px;

                word-break: break-word;
                cursor: pointer;
                text-align: center;
                font-family: inherit;

                position: relative;

                @media (max-width: 600px) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    padding: 24px 16px;
                }

                .check-box {
                    min-width: 24px;
                    min-height: 24px;
                    width: 24px;
                    height: 24px;

                    position: absolute;
                    left: 7px;
                    top: 7px;

                    @media (max-width: 600px) {
                        position: initial;
                        left: initial;
                        top: initial;

                        order: 1;
                    }

                    border: 2px solid #999999;
                    background: $unnamed-color-ffffff 0% 0% no-repeat
                        padding-box;
                    border-radius: 2px;

                    &.selected {
                        border: 2px solid #2A92C0;
                    }
                }

                .selected-box {
                    width: 24px;
                    height: 24px;

                    position: absolute;
                    left: 7px;
                    top: 7px;

                    background: $unnamed-color-ffffff 0% 0% no-repeat
                        padding-box;
                    border: 2px solid $unnamed-color-999999;
                    border-radius: 12px;

                    @include flex-center;

                    @media (max-width: 600px) {
                        position: initial;
                        left: initial;
                        top: initial;

                        order: 1;
                    }

                    .mark {
                        width: 14px;
                        height: 14px;

                        background: #2A92C0 0% 0% no-repeat
                            padding-box;
                        border-radius: 12px;
                    }

                    &.selected {
                        border: 2px solid #2A92C0;
                    }
                }

                .icon {
                    width: 42px;
                    height: 42px;

                    margin: 0 auto;
                    display: block;

                    @media (max-width: 600px) {
                        order: 3;
                    }
                }

                span {
                    margin: 0;
                    display: block;

                    flex: 1;

                    @media (max-width: 600px) {
                        order: 2;
                    }
                }

                &.selected {
                    border: 2px solid #2A92C0;
                    background: #2A92C01A 0% 0% no-repeat padding-box;
                }
            }
        }
    }

    .spacer {
        height: 2px;
        margin: 32px 0 16px 0;

        background-color: $unnamed-color-999999;
        border: none;
    }

    .input-container {
        input {
            width: 100%;
            padding: 9px 16px;
            margin: 0 auto;

            display: block;

            @include normal-text-16;
            color: #333333;
            font-family: inherit;

            border: 2px solid $unnamed-color-999999;
            background: #CCCCCC 0% 0% no-repeat padding-box;
            background-color: #FFFFFF;
        }
    }

    .page-controls {
        @include flex-between-center;

        @media (max-width: 600px) {
            flex-direction: column-reverse;
            gap: 4px;

            button {
                width: 100%;
            }
        }

        button {
            display: block;
            padding: 9px 16px;

            color: white;
            border: none;
            @include bold-text-16;
            text-transform: uppercase;
            font-family: inherit;
            cursor: pointer;

            &.next {
                background: #2A92C0 0% 0% no-repeat padding-box;
            }

            &.complete {
                background: #2A92C0 0% 0% no-repeat padding-box;
            }

            &.back {
                background: #CCCCCC 0% 0% no-repeat padding-box;
            }
        }
    }

    .help-info {
        margin-top: 20px;

        @include flex-left;
        @include bold-text-16;

        color: $unnamed-color-999999;

        .icon {
            width: 16px;
            height: 16px;

            margin-right: 8px;
        }

        span {
            margin-left: 8px;

            @include normal-text-16;
            color: $unnamed-color-999999;
        }
    }
}
</style>