// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-64.use[1]!../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-64.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-64.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-64.use[4]!../../node_modules/@vue/vue-loader-v15/lib/index.js??vue-loader-options!./Checkbox.vue?vue&type=style&index=0&id=09b963e2&prod&lang=scss&scoped=true&");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!../../node_modules/vue-style-loader/lib/addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("fcd87a96", content, shadowRoot)
};