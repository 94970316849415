var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('p',{staticClass:"current-page"},[_vm._v("Frage "+_vm._s(_vm.currentPage)+" von "+_vm._s(_vm.maxPages))]),_c('h2',{staticClass:"titel"},[_vm._v(_vm._s(_vm.page.title))]),_c('div',{staticClass:"data",domProps:{"innerHTML":_vm._s(_vm.page.data)}}),(_vm.page.elementType == 3)?_c('p',{staticClass:"extra-text"},[_vm._v(" "+_vm._s(_vm.page.elements[0] && _vm.page.elements[0].label ? _vm.page.elements[0].label : 'Freien Text eingeben')+" ")]):_vm._e(),(_vm.page.elementType == 1 || _vm.page.elementType == 2)?_c('div',{staticClass:"item-container"},[_c('div',{staticClass:"selection"},_vm._l((_vm.page.elements),function(element){return _c('button',{key:element.id,staticClass:"item",class:{
                    selected:
                        _vm.answer != undefined &&
                        ((_vm.page.elementType == 1 &&
                            _vm.answer.elementID == element.id) ||
                            (_vm.page.elementType == 2 &&
                                _vm.answer.selection.includes(element.id))),
                },on:{"click":function($event){return _vm.$emit('answer', {
                        pageID: _vm.page.id,
                        elementType: _vm.page.elementType,
                        elementID: element.id,
                    })}}},[(_vm.page.elementType == 2)?_c('div',{staticClass:"check-box",class:{
                        selected:
                            _vm.answer != undefined &&
                            _vm.page.elementType == 2 &&
                            _vm.answer.selection.includes(element.id),
                    }},[_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.answer != undefined &&
                            _vm.page.elementType == 2 &&
                            _vm.answer.selection.includes(element.id)
                        ),expression:"\n                            answer != undefined &&\n                            page.elementType == 2 &&\n                            answer.selection.includes(element.id)\n                        "}],attrs:{"icon":['fas', 'check']}})],1):_vm._e(),(_vm.page.elementType == 1)?_c('div',{staticClass:"selected-box",class:{
                        selected:
                            _vm.answer != undefined &&
                            _vm.page.elementType == 1 &&
                            _vm.answer.elementID == element.id,
                    }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.answer != undefined &&
                            _vm.page.elementType == 1 &&
                            _vm.answer.elementID == element.id
                        ),expression:"\n                            answer != undefined &&\n                            page.elementType == 1 &&\n                            answer.elementID == element.id\n                        "}],staticClass:"mark"})]):_vm._e(),(element.icon != '' && element.icon != null)?_c('img',{staticClass:"icon",attrs:{"src":_vm.assetUrl + element.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(element.data))])])}),0)]):_vm._e(),(_vm.page.elementType == 3 && _vm.page.elements[0])?_c('div',{staticClass:"input-container"},[_c('input',{ref:"elementText",attrs:{"placeholder":_vm.page.elements[0].data,"type":"text"}})]):_vm._e(),_c('hr',{staticClass:"spacer"}),_c('div',{staticClass:"page-controls"},[(_vm.currentPage != 1)?_c('button',{staticClass:"back",on:{"click":_vm.previousPage}},[_vm._v(" Zurück ")]):_vm._e(),_c('button',{class:{
                next: _vm.currentPage != _vm.maxPages,
                complete: _vm.currentPage == _vm.maxPages,
            },on:{"click":_vm.nextPage}},[_vm._v(" "+_vm._s(_vm.currentPage == _vm.maxPages ? "Anfrage absenden" : "Weiter")+" ")])]),(
            _vm.survey.phonenumber != null &&
            _vm.survey.phonenumber != undefined &&
            _vm.survey.phonenumber != ''
        )?_c('p',{staticClass:"help-info"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'question-circle']}}),_vm._v("Brauchen Sie Hilfe?"),_c('span',[_vm._v("Rufen Sie uns an: "+_vm._s(_vm.survey.phonenumber))])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }