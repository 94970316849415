<template>
    <button class="checkbox" @click="$emit('input', !value)"><font-awesome-icon v-show="value" :icon="['fas', 'check']" /></button>
</template>

<script>
    export default {
        name: "CheckboxElement",
        props: {
            value: Boolean
        }
    };
</script>

<style lang="scss" scoped>
    .checkbox {
        width: 24px;
        height: 24px;

        min-width: 24px;
        min-height: 24px;

        display: inline-block;
        cursor: pointer;

        @include normal-text-16;
        color: #2A92C0;

        border: 2px solid #999999;
        background: #FFFFFF 0% 0% no-repeat padding-box;
    }
</style>