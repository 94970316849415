import { render, staticRenderFns } from "./Checkbox.vue?vue&type=template&id=09b963e2&scoped=true&"
import script from "./Checkbox.vue?vue&type=script&lang=js&"
export * from "./Checkbox.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Checkbox.vue?vue&type=style&index=0&id=09b963e2&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "09b963e2",
  null
  ,true
)

export default component.exports