<template>
    <div v-if="!sent" class="end-page">
        <p class="current-page">Ende</p>
        <h2 class="titel">Bitte gib uns deine Kontaktdaten. Wir melden uns schnellstmöglich bei dir.</h2>
        <div class="inputs">
            <div v-if="(survey.informationType & 2) == 2" class="row">
                <label for="salutation">Anrede</label>
                <input id="salutation" v-model="personalData.salutation" placeholder="Deine Anrede" type="text">
            </div>
            <div v-if="(survey.informationType & 4) == 4" class="row">
                <label for="firstname">Vorname</label>
                <input id="firstname" v-model="personalData.firstname" placeholder="Dein Vorname" type="text">
            </div>
            <div v-if="(survey.informationType & 8) == 8" class="row">
                <label for="lastname">Nachname</label>
                <input id="lastname" v-model="personalData.lastname" placeholder="Dein Nachname" type="text">
            </div>
            <div v-if="(survey.informationType & 1) == 1" class="row">
                <label for="email">E-Mail</label>
                <input id="email" v-model="personalData.email" placeholder="Deine E-Mail-Adresse" type="text">
            </div>
            <div v-if="(survey.informationType & 16) == 16" class="row">
                <label for="phonenumber">Telefonnummer</label>
                <input id="phonenumber" v-model="personalData.phonenumber" placeholder="Deine Telefonnummer" type="text">
            </div>
            <div v-if="(survey.informationType & 32) == 32" class="row">
                <label class="text-top" for="more-information">Weitere Informationen</label>
                <textarea id="more-information" v-model="personalData.moreInformation" rows="4"></textarea>
            </div>
            <div class="row">
                <p>Datenschutz</p>
                <div class="privacy"><Checkbox class="checkbox" v-model="personalData.acceptedPrivacy" /><p>Ich akzeptiere die <a target="_blank" href="https://hp-immobilien.myc3.dev/datenschutz">Datenschutzerklärung</a></p></div>
            </div>
            <hr class="spacer">
            <div class="page-controls">
                <button class="back" @click="$emit('cancel')">Zurück</button>
                <button class="send" @click="sendSurvey">Anfrage absenden</button>
            </div>
            <p v-if="survey.phonenumber && survey.phonenumber != ''" class="help-info"><font-awesome-icon class="icon" :icon="['fas', 'question-circle']" />Brauchen Sie Hilfe?<span>Rufen Sie uns an: {{ survey.phonenumber }}</span></p>
        </div>
    </div>
    <div class="end-message" v-else>
        <img class="check-icon" :src="assetUrl + 'Check mark button/3D/check_mark_button_3d.png'" />
        <h2 class="title">Alles fertig! Danke für deine Anfrage.</h2>
        <p>Wir melden uns schnellstmöglich bei dir.</p>
    </div>
</template>

<script>
    import Checkbox from './Checkbox';
    import { getAssetsUrl } from '@/config';

    export default {
        name: 'EndPage',
        components: {
            Checkbox
        },
        props: {
            survey: Object
        },
        data: function() {
            return {
                sent: false,
                personalData: {
                    salutation: null,
                    firstname: null,
                    lastname: null,
                    email: null,
                    phonenumber: null,
                    moreInformation: null,
                    acceptedPrivacy: false
                },
                assetUrl: getAssetsUrl()
            }
        },
        methods: {
            sendSurvey() {
                if(this.personalData.acceptedPrivacy) {
                    this.sent = true;
                    this.$emit("send", this.personalData);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .end-page {
        .current-page {
            margin-bottom: 8px;

            font-size: 12px;
            color: #999999;
        }

        .titel {
            margin-bottom: 32px;

            color: #333333;
            word-break: break-word;
        }

        .text {
            margin-top: 16px;

            @include normal-text-16;
            color: black;
            word-break: break-word;

            > a {
                margin-left: 5px;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            margin-top: 16px;

            .row {
                @include normal-text-16;
                color: black;

                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-end;
                
                > label, p {
                    display: block;
                    margin-bottom: 4px;

                    font-size: 12px;
                    color: #333;
                }

                > div {
                    flex: 8;

                    > * {
                        vertical-align: middle;
                    }

                    > .checkbox {
                        display: inline-block;

                        margin-right: 10px;
                    }

                    > p {
                        display: inline-block;
                    }

                    > a {
                        display: inline-block;

                        margin-left: 5px;
                    }
                }

                > input {
                    padding: 9px 16px;

                    @include normal-text-16;
                    color: #333333;
                    font-family: inherit;

                    border: 2px solid #CCCCCC;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                }

                > textarea {
                    height: 120px;
                    flex: 2;

                    padding: 16px 16px;

                    @include normal-text-16;
                    color: black;
                    resize: none;

                    font-family: inherit;
                    font-size: inherit;

                    border: 2px solid $unnamed-color-999999;
                    background: #99999933 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                }
            }

            .privacy {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    display: inline-block;
                    margin-bottom: 0;

                    font-size: 16px;
                    color: #333;
                    line-height: 1.5em;

                    > a {
                        color: #333;
                    }
                }
            }
        }

        .spacer {
            height: 2px;
            margin: 32px 0 16px 0;

            background-color: $unnamed-color-999999;
            border: none;
        }

    .page-controls {
        @include flex-between-center;

        @media (max-width: 600px) {
            flex-direction: column-reverse;
            gap: 4px;

            button {
                width: 100%;
            }
        }

        button {
            display: block;
            padding: 9px 16px;

            color: white;
            border: none;
            @include bold-text-16;
            text-transform: uppercase;
            font-family: inherit;
            cursor: pointer;

            &.next {
                background: #E20613 0% 0% no-repeat padding-box;
            }

            &.send {
                background: #2A92C0 0% 0% no-repeat padding-box;
            }

            &.back {
                background: #CCCCCC 0% 0% no-repeat padding-box;
            }
        }
    }

        .help-info {
            margin-top: 20px;

            @include flex-left;
            @include bold-text-16;

            color: $unnamed-color-999999;

            .icon {
                width: 16px;
                height: 16px;

                margin-right: 8px;
            }

            span {
                margin-left: 8px;

                @include normal-text-16;
                color: $unnamed-color-999999;
            }
        }

        .end-text {
            text-align: center;
        }
    }

    .end-message {
        .check-icon {
            width: 42px;
            height: 42px;
            margin-bottom: 20px;
        }

        .title {
            margin-bottom: 10px;

            color: #333333;
            word-break: break-word;
        }
    }
</style>